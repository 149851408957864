<template>
  <main>
    <div class="page-content">
      <div v-if="isMessageSent">
        <p>Your message has been sent. You will hear back from us soon.</p>
      </div>
      <form v-else @submit.prevent="handleSend" class="edit-form">
        <h3>Send us a message</h3>
        <div class="form-outline mb-4">
          <label>Your name</label>
          <input type="text" v-model="name" class="form-control" required />
        </div>
        <div class="form-outline mb-4">
          <label>Your email address</label>
          <input
            type="text"
            v-model="email"
            class="form-control"
            required
            @blur="validateEmail"
          />
        </div>
        <div class="form-outline mb-4">
          <label>Subject</label>
          <input type="text" v-model="subject" class="form-control" required />
        </div>
        <div class="form-outline mb-4">
          <label>Message</label>
          <textarea v-model="message" class="form-control long-text" required>
          </textarea>
        </div>
        <div class="error">{{ formError }}</div>
        <div class="row text-center">
          <div class="col">
            <div v-if="!isPending">
              <div v-if="formError">
                <button class="btn btn-secondary" disabled>Send Message</button>
              </div>
              <div v-else>
                <button class="btn btn-primary">Send Message</button>
              </div>
            </div>
            <div v-else>
              <button class="btn btn-primary" disabled>
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Sending...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </form>
      <div class="p-4 text-center">
        <p>or call us</p>
        <h3>(804) 250-9380</h3>
      </div>
    </div>
  </main>
  <Footer />
</template>

<script>
import Footer from "@/components/navigation/Footer.vue";
import { ref } from "@vue/reactivity";
import { projectFunctions } from "@/firebase/config";

export default {
  name: "Contact",
  components: { Footer },
  setup() {
    const isMessageSent = ref(false);
    const isPending = ref(false);
    const name = ref(null);
    const email = ref(null);
    const subject = ref(null);
    const message = ref(null);
    const formError = ref(null);
    const validEmail = ref(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    const sendSupportMessage = projectFunctions.httpsCallable(
      "sendSupportMessage"
    );

    const validateEmail = () => {
      formError.value = "";
      if (!validEmail.value.test(email.value)) {
        formError.value = "Invalid Email Address.";
      }
    };

    const handleSend = async () => {
      try {
        console.log("Send Message");
        isPending.value = true;
        formError.value = null;
        isMessageSent.value = false;
        let supportMessage = {
          name: name.value,
          replyTo: `${name.value} <${email.value}>`,
          message: {
            subject: subject.value,
            text: `${message.value}\n\n${name.value}`,
            html: `${message.value}<br><br>${name.value}`
          },
          isLoggedIn: false,
          application: "home"
        };
        console.log("Call sendSupportMessage");
        const sendResult = await sendSupportMessage(supportMessage);
        if (sendResult.data.error) {
          formError.value = sendResult.data.error;
        } else {
          name.value = email.value = subject.value = message.value = "";
          isMessageSent.value = true;
          window.scrollTo(0, 0);
        }
        isPending.value = false;
      } catch (err) {
        console.log(`ERROR: ${err}`);
        isPending.value = false;
        formError.value = err.message;
      }
    };

    return {
      isMessageSent,
      name,
      email,
      subject,
      message,
      formError,
      isPending,
      handleSend,
      validateEmail
    };
  }
};
</script>

<style>
</style>